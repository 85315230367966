import React, { useEffect, useState } from 'react';
import { Link, Table, TableBody, TableCell, TableHead, TableRow, KeyboardArrowUpIcon, KeyboardArrowDownIcon, Paper, Typography, TableRow, TableHead, TableContainer, TableCell, TableBody, Collapse, Button } from '@material-ui/core';
import useStyles from './styles';
import getBlockchain from '../BlockChain/Contract';
import CastVote from './CastVote';
import PollState from './PollState';
import { ethers } from "ethers";
import PollResult from './PollResult';

export default function PollCard({ polls, contract, accounts }) {
  const [statuses, setStatuses] = useState([]);
  const classes = useStyles();

  console.log(contract)

  const getCandidates = async (pollId) => {
    if (contract) {
      const candidates = await contract.methods.candidatesOfPoll(1).call();
      console.log(candidates)
    }
  }



  useEffect(() => {
    getCandidates(0);
  }, [contract])


  return (
    <TableContainer className={classes.table} component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="caption table">
        <caption>cast your vote in which group you have created register sertificate</caption>
        <TableHead>
          <TableRow className={classes.tableRow}>
            <TableCell sx={{ color: 'blue' }}>poll&nbsp;id</TableCell>
            <TableCell align="center">poll&nbsp;name</TableCell>
            <TableCell align="center">poll&nbsp;candidates</TableCell>
            <TableCell align="center">description</TableCell>
            <TableCell align="center">status</TableCell>
            <TableCell align="center">result</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {polls.slice(0).reverse().map((poll) => (
            <TableRow key={poll.groupId}>
              <TableCell component="th" scope="row">
                {poll.groupId}
                <CastVote pollId={poll.groupId} contract={contract} candidates={poll.groupCandidates} description={poll.groupDescription} accounts={accounts}/>
              </TableCell>

              <TableCell align="center">{poll.groupName}</TableCell>
              <TableCell align="center">{poll.groupCandidates.map((c) => { return ` ${c} ,` })}</TableCell>
              <TableCell align="center">{poll.groupDescription}</TableCell>
              <PollState pollId={poll.groupId} contract={contract} />
              <PollResult pollId={poll.groupId} contract={contract} />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

