import React, { useState, useEffect } from 'react';
import { Container, Grow, Grid } from '@material-ui/core';
import Footer from '../Footer/Footer';
import Poll from '../Poll/Poll';

const Home = () => {


  return (
    <Grow in>
    <Container>
      <Grid   justify="space-between" alignItems="stretch" spacing={3}>
        <Poll/>
        <div>
        <Footer/>
        </div>
      </Grid>
    </Container>
  </Grow>
  )
}

export default Home;
