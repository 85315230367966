{
  "_args": [
    [
      "ejs@3.1.8",
      "/opt/render/project/src"
    ]
  ],
  "_development": true,
  "_from": "ejs@3.1.8",
  "_id": "ejs@3.1.8",
  "_inBundle": false,
  "_integrity": "sha512-/sXZeMlhS0ArkfX2Aw780gJzXSMPnKjtspYZv+f3NiKLlubezAHDU5+9xz6gd3/NhG3txQCo6xlglmTS+oTGEQ==",
  "_location": "/ejs",
  "_phantomChildren": {},
  "_requested": {
    "type": "version",
    "registry": true,
    "raw": "ejs@3.1.8",
    "name": "ejs",
    "escapedName": "ejs",
    "rawSpec": "3.1.8",
    "saveSpec": null,
    "fetchSpec": "3.1.8"
  },
  "_requiredBy": [
    "/snarkjs"
  ],
  "_resolved": "https://registry.npmjs.org/ejs/-/ejs-3.1.8.tgz",
  "_spec": "3.1.8",
  "_where": "/opt/render/project/src",
  "author": {
    "name": "Matthew Eernisse",
    "email": "mde@fleegix.org",
    "url": "http://fleegix.org"
  },
  "bin": {
    "ejs": "bin/cli.js"
  },
  "bugs": {
    "url": "https://github.com/mde/ejs/issues"
  },
  "dependencies": {
    "jake": "^10.8.5"
  },
  "description": "Embedded JavaScript templates",
  "devDependencies": {
    "browserify": "^16.5.1",
    "eslint": "^6.8.0",
    "git-directory-deploy": "^1.5.1",
    "jsdoc": "^3.6.7",
    "lru-cache": "^4.0.1",
    "mocha": "^7.1.1",
    "uglify-js": "^3.3.16"
  },
  "engines": {
    "node": ">=0.10.0"
  },
  "homepage": "https://github.com/mde/ejs",
  "jsdelivr": "ejs.min.js",
  "keywords": [
    "template",
    "engine",
    "ejs"
  ],
  "license": "Apache-2.0",
  "main": "./lib/ejs.js",
  "name": "ejs",
  "repository": {
    "type": "git",
    "url": "git://github.com/mde/ejs.git"
  },
  "scripts": {
    "test": "mocha"
  },
  "unpkg": "ejs.min.js",
  "version": "3.1.8"
}
