import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import { Button, Typography, Box, Modal, Paper, Container, TextField } from '@material-ui/core';
import axios from 'axios';
import PollCard from './PollCard';
import getBlockchain from '../BlockChain/Contract';
import ConnectMetamask from '../NavBar';

const Admin = () => {
    const [pollId, setPollId] = useState();
    const [waiting, setWaiting] = useState(false);
    const [polls, setPolls] = useState([]);
    const [votingContract, setContract] = useState(undefined);
    const [candidateArrey, setCandidateArrey] = useState([]);
    const [adminAddress, setAdminAddress] = useState('');
    const [pollName, setPollName] = useState('');
    const [pollDescription, setPollDescription] = useState('');
    const [notExist, setNotExist] = useState(true);

    const [accounts, setAccounts] = useState([]);
    const [isConnected, setIsConnected] = useState(Boolean);

    console.log(isConnected)

    const classes = useStyles();

    const user = JSON.parse(localStorage.getItem('profile'));

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleBudget = async (e) => {
        window.location.replace('https://devoting-budget.onrender.com');
    }

    const contractExist = async () => {
        if (votingContract) {
            console.log('hey')
            setNotExist(false)
        }
    }

    const getGroups = async () => {
        const newData = await axios.get(
            `https://devotingversion02.onrender.com/user/getGroups`,

        );
        console.log(newData.data.allGroups);
        setPolls(newData.data.allGroups);
    };

    useEffect(() => {
        getGroups();
        const init = async () => {
            const { contract } = await getBlockchain();
            setContract(contract);
        }
        init();
        contractExist();
    }, [votingContract]);

    console.log(votingContract)

    return (
        <div>
            <Container component="main" maxWidth="lg">
                <ConnectMetamask accounts={accounts} setAccounts={setAccounts} setIsConnected={setIsConnected} />
                <Paper className={classes.paper}>
                    <Typography variant="h4" align="center">
                        Polls
                    </Typography>
                    <br></br>
                    <div>
                        {waiting ? (
                            <Typography variant="h6" align="center">
                                ...waiting
                            </Typography>
                        ) : (
                            <div>
                                {
                                    polls?.length > 0 ? (
                                        <div>
                                            {
                                                votingContract ? (
                                                    <div>
                                                        {
                                                            <PollCard polls={polls} contract={votingContract} accounts={accounts} />
                                                        }
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <div className="empty">
                                                            <h2>No Contract found</h2>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    ) : (
                                        <div>
                                            <div className="empty">
                                                <h2>No poll found</h2>
                                            </div>
                                        </div>
                                    )
                                }

                                <form autoComplete="off" noValidate className={`${classes.root} ${classes.form}`} onSubmit={handleBudget}>

                                    <div>
                                        <Button href='https://devoting-budget.onrender.com' className={classes.buttonSubmit} variant="contained" color="primary" size="large" type="submit" fullWidth onClick={handleBudget}>create new poll</Button>

                                    </div>

                                </form>
                            </div>
                        )}
                    </div>
                </Paper>
            </Container>

        </div>
    )
}

export default Admin;
