'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function keyframes() {
	return {
		__html: "@keyframes spin { 0% { transform: rotate(0deg) } 100% { transform: rotate(360deg) }}"
	};
}

function style(size, stroke, color) {
	return {
		inner: {
			animation: 'spin 0.5s linear infinite',
			height: size,
			width: size,
			border: stroke + ' solid transparent',
			borderTopColor: color,
			borderRadius: '100%',
			boxSizing: 'border-box'
		},
		outer: {
			height: '100%',
			width: '100%',
			display: 'flex',
			flexFlow: 'column nowrap',
			justifyContent: 'center',
			alignItems: 'center'
		}
	};
}

var Loading = function Loading(_ref) {
	var _ref$size = _ref.size;
	var size = _ref$size === undefined ? '50px' : _ref$size;
	var _ref$stroke = _ref.stroke;
	var stroke = _ref$stroke === undefined ? '3px' : _ref$stroke;
	var _ref$color = _ref.color;
	var color = _ref$color === undefined ? '#000' : _ref$color;

	var styles = style(size, stroke, color);
	return _react2.default.createElement(
		'div',
		{ style: styles.outer },
		_react2.default.createElement('div', { style: styles.inner }),
		_react2.default.createElement('style', { dangerouslySetInnerHTML: keyframes() })
	);
};

exports.default = Loading;