import React, { useEffect, useState } from 'react';
import { TableCell } from '@material-ui/core';
import { ethers } from "ethers";
import Loading from 'react-simple-loading';

const PollResult = ({ pollId, contract }) => {
    const [loading, setLoading] = useState(false);

    const [firstOptionResult, setFirstOptionResult] = useState([])
    const [secondOptionResult, setSecondOptionResult] = useState([])
    const [thirdOptionResult, setThirdOptionResult] = useState([])

    const result = async () => {
        if (contract) {

            setLoading(true)
            const currentResult = await contract.methods.getResult(pollId).call();
            console.log(typeof (currentResult), currentResult, currentResult.length)

            if (currentResult.length > 0) {
                for (let i = 0; i < currentResult.length; i++) {
                    if (ethers.utils.formatBytes32String("0") === currentResult[i]) {
                        firstOptionResult.push(currentResult[i]);
                    } else if (ethers.utils.formatBytes32String("1") === currentResult[i]) {
                        secondOptionResult.push(currentResult[i]);
                    } else if (ethers.utils.formatBytes32String("2") === currentResult[i]) {
                        thirdOptionResult.push(currentResult[i]);
                    }
                }

            }
            setLoading(false)
        }
    }

    useEffect(() => {
        result();
    }, [contract])

    return (
        <TableCell align="center">
            {
                !loading ? (
                    <div>
                        {`${firstOptionResult.length.toString()} , ${secondOptionResult.length.toString()} , ${thirdOptionResult.length.toString()}`}
                    </div>
                ) : (
                    <div>
                        <Loading
                            color={'blue'}
                            stroke={'2px'}
                            size={'15px'} />
                    </div>
                )
            }
        </TableCell>
    )
}

export default PollResult