import React, { useEffect, useState } from 'react'
import {TableCell } from '@material-ui/core';
import useStyles from './styles';

const PollState = ({ pollId , contract }) => {

  console.log(contract)

  const [state, setState] = useState('...');

  const classes = useStyles();

  const getAStatus = async () => {
    if (contract) {
      const status = await contract.methods.getGroupStatus(pollId).call();
      
      console.log(status)

      if (status == 0) {
        setState ('Created');
      } else if (status == 1) {
        setState ('Ongoing');
      } else if (status == 2) {
        setState ('Ended');
      }
    }
  }

  useEffect(() => {
    getAStatus();
  }, [contract])

  return (
    <TableCell align="center">{state}</TableCell>
  )
}

export default PollState



// import React, { useEffect, useState } from 'react'
// import getBlockchain from '../BlockChain/Contract';
// import { TableCell } from '@material-ui/core';
// import useStyles from './styles';

// const PollState = ({ pollId, contract }) => {
//     new Promise((resolve, reject) => {
//         window.addEventListener('load', async () => {
//             if (contract) {

//                 const state = ''
//                 if (contract) {
//                     const status = await contract.methods.getGroupStatus(pollId).call();
//                     console.log(status)

//                     if (status == 0) {
//                         state = 'Created'
//                     } else if (status == 1) {
//                         state = 'Ongoing';
//                     } else if (status == 2) {
//                         state = 'Ended';
//                     }

//                 }

//                 resolve({ state });
//             }
//             resolve({ state: '...' });
//         })

//     })

// }

// export default PollState
