import React, { useEffect, useState } from 'react'
import useStyles from './styles';
import { Button, Modal, Avatar, TextField, Typography, Box, Paper, Card, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Grid } from '@material-ui/core';
import { Identity } from '@semaphore-protocol/identity';
import axios from 'axios';
import getBlockchain from '../BlockChain/Contract';
import voter from '../images/voter.png'
import { Identity } from "@semaphore-protocol/identity";
import { Group } from "@semaphore-protocol/group";
import { generateProof, packToSolidityProof, verifyProof } from "@semaphore-protocol/proof"
import { ethers } from "ethers";
import Loading from 'react-simple-loading';


const CastVote = ({ pollId, contract, candidates, description, accounts }) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [state, setState] = useState('Ongoing');
  const [allGroupsServer, setAllGroupsServer] = useState([]);
  const [pending, setPending] = useState(false);

  const [vote, setVote] = useState('');
  const [_identity, setIdentity] = useState('');
  const [_merkleRoot, setMerkleTreeRoot] = useState('');
  const [_nullifierHash, setNullifierHash] = useState('');
  const [_proof, setProof] = useState('');

  const classes = useStyles();

  const castVote = async (e) => {
    e.preventDefault();

    const group = new Group();

    var index = allGroupsServer.findIndex(item => item.groupId == pollId);
    console.log(index)

    allGroupsServer[index].commitments.forEach((commitment) => {
      group.addMember(commitment);
    })

    console.log(group.members); // they already commited 
    // know they should create proof with this group and thier identities 

    const realIdentity = new Identity(_identity);
    console.log(realIdentity);

    const fullProof = await generateProof(realIdentity, group, pollId, vote);
    const solidityProof = packToSolidityProof(fullProof.proof);

    console.log(fullProof)

    console.log(vote, ethers.utils.formatBytes32String(vote))
    console.log(fullProof.publicSignals.merkleRoot)
    console.log(fullProof.publicSignals.nullifierHash)
    console.log(solidityProof)
    setPending(true)
    if (contract) {
      await contract.methods.castVote(
        ethers.utils.formatBytes32String(vote),
        fullProof.publicSignals.nullifierHash,
        fullProof.publicSignals.merkleRoot,
        pollId,
        solidityProof
      ).send(
        { from: accounts[0] }
      ).then(async (instance) => {
        alert(`your vote (${instance.events.VoteAdded.returnValues[1]}) was added in poll id ${instance.events.VoteAdded.returnValues[0]}`);
        window.location.reload();
      }).catch((error) => {
        alert(error.message);
        setPending(false)
      })
    } else {
      alert('contract did not find!')
    }
  }

  const createAnonimouseVote = async (e) => {
    e.preventDefault();

    const group = new Group();
    console.log(pollId)
    var index = allGroupsServer.findIndex(item => item.groupId == pollId);
    console.log(index)
    console.log(allGroupsServer[index])

    allGroupsServer[index].commitments.forEach((commitment) => {
      group.addMember(commitment);
    })

    console.log(group.members); // they already commited 
    // know they should create proof with this group and thier identities 

    const realIdentity = new Identity(_identity);
    console.log(realIdentity);

    const fullProof = await generateProof(realIdentity, group, pollId, vote);
    const solidityProof = packToSolidityProof(fullProof.proof);

    setProof(solidityProof);

    // console.log(fullProof)

    // console.log(vote)
    // console.log(fullProof.publicSignals.merkleRoot)
    // console.log(fullProof.publicSignals.nullifierHash)
    // console.log(_proof)

  }

  const handleModal = async (e) => {
    e.preventDefault();
    handleOpen()
  }

  const handleOpen = () => {
    if (accounts[0]) {
      if (state == 'Ongoing') {
        setOpen(true)
      } else {
        alert('Cannot participate in')
      }
    } else {
      alert('Connect Wallet')
    }
  };
  const handleClose = () => setOpen(false);

  const getAStatus = async () => {
    if (contract) {
      const status = await contract.methods.getGroupStatus(pollId).call();
      // console.log(status)

      if (status == 0) {
        setState('Created');
      } else if (status == 1) {
        setState('Ongoing');
      } else if (status == 2) {
        setState('Ended');
      }
    }

  }

  const getGroup = async () => {
    const group = await axios.get(
      `https://devotingversion02.onrender.com/user/getGroups`
    );
    // console.log(group.data)
    return (group);
  }

  useEffect(() => {
    getGroup().then((response) => {
      // console.log(response.data);
      setAllGroupsServer(response.data.allGroups);
    });
    getAStatus();
  }, [contract])

  return (
    <div>
      <Button color={state == 'Ongoing' ? 'primary' : 'default'} disabled={state !== 'Ongoing'} variant="contained" size='small' onClick={handleOpen}>vote</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          height: 800,
          backgroundColor: '#eeeeee',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}>
          <Avatar src={voter} className={classes.avatar}></Avatar>

          <Typography id="modal-modal-title" variant="h5" component="h2" align="center">
            Poll ID {pollId}
          </Typography>
          {
            pending ? (
              <div>
                <div>
                  <Paper className={classes.paperJoin}>
                    <Typography align='center' variant='h4' component='h1' >
                      ...pending
                    </Typography>
                    <Loading size={'100px'} />
                  </Paper>
                </div>
              </div>
            ) : (
              <div>
                {
                  !isDisabled ? (
                    <Paper className={classes.paperJoin}>
                      <br></br>
                      <Grid container spacing={1}>
                        <Grid item xs={8}>
                          {
                            <div>
                              <FormLabel id="demo-radio-buttons-group-label">description</FormLabel>
                              <br></br>
                              <Typography id="modal-modal-title" variant="h6" component="h5">{description}</Typography>
                            </div>
                          }
                        </Grid>
                        <Grid item xs={4}>
                          <FormControl>
                            <FormLabel id="demo-radio-buttons-group-label">candidate</FormLabel>
                            <RadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              defaultValue="female"
                              name="radio-buttons-group"
                            >
                              {
                                candidates.map((c, index) => {
                                  return <FormControlLabel value={index.toString()} control={<Radio />} label={c} onChange={(e) => setVote(e.target.value.toString())} />
                                })
                              }
                            </RadioGroup>
                          </FormControl>
                        </Grid>

                        <form className={classes.form} onSubmit={castVote}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>

                              <TextField
                                className={classes.textField}
                                name='register sertificate'
                                onChange={(e) => setIdentity(e.target.value.toString())}
                                variant="outlined"
                                required
                                fullWidth
                                label='register sertificate'
                                autoFocus
                                type='text'
                              />

                            </Grid>
                          </Grid>

                          <Button type="submit" onClick={createAnonimouseVote} disabled={!vote} fullWidth variant="contained" color="primary" className={classes.submit}>
                            create Anonimouse Vote
                          </Button>

                          <br></br>  <br></br>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                              <TextField
                                className={classes.textField}
                                name='ananimouse vote'
                                variant="outlined"
                                required
                                fullWidth
                                label='ananimouse vote'
                                autoFocus
                                type='text'
                                value={_proof}
                                disabled
                              />
                            </Grid>
                          </Grid>

                          {
                            _proof ? (
                              <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                                cast ananimouse vote
                              </Button>
                            ) : (
                              <Button type="submit" disabled="true" fullWidth variant="contained" color="primary" className={classes.submit}>
                                cast ananimouse vote
                              </Button>)
                          }
                          {/* <Grid container justify="flex-end">
                      <Grid item>
                        <Button onClick>
                          Don't have Register Sertificate
                        </Button>
                      </Grid>
                    </Grid> */}
                        </form>


                      </Grid>
                    </Paper>
                  ) : (
                    <div>
                      {
                        waiting ? (
                          <div>
                            <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
                            <Typography sx={{ marginTop: '50px', top: '50%' }} variant="h6" align="center">
                              ...pending
                            </Typography>
                          </div>
                        ) : (
                          <div>
                            <Paper className={classes.paperJoin}>
                              <Card className={classes.card} >
                                <Typography variant="body2" color="textSecondary" component="p">{identity}</Typography>
                              </Card>
                              <CopyToClipboard text={identity}>
                                <Button size="small" color="primary" onClick>
                                  <CopyAllIcon fontSize="small" /> Copy
                                </Button>
                              </CopyToClipboard>
                            </Paper>

                          </div>
                        )
                      }
                    </div>
                  )
                }
              </div>
            )
          }
        </Box>
      </Modal>
    </div>
  )
}

export default CastVote;
