/**
 * @module @semaphore-protocol/group
 * @version 2.6.1
 * @file A library to create and manage Semaphore groups.
 * @copyright Ethereum Foundation 2022
 * @license MIT
 * @see [Github]{@link https://github.com/semaphore-protocol/semaphore/tree/main/packages/group}
*/
'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var incrementalMerkleTree = require('@zk-kit/incremental-merkle-tree');
var circomlibjs = require('circomlibjs');

var Group = /** @class */ (function () {
    /**
     * Initializes the group with the tree depth and the zero value.
     * @param treeDepth Tree depth.
     * @param zeroValue Zero values for zeroes.
     */
    function Group(treeDepth, zeroValue) {
        if (treeDepth === void 0) { treeDepth = 20; }
        if (zeroValue === void 0) { zeroValue = BigInt(0); }
        if (treeDepth < 16 || treeDepth > 32) {
            throw new Error("The tree depth must be between 16 and 32");
        }
        this._merkleTree = new incrementalMerkleTree.IncrementalMerkleTree(circomlibjs.poseidon, treeDepth, zeroValue, 2);
    }
    Object.defineProperty(Group.prototype, "root", {
        /**
         * Returns the root hash of the tree.
         * @returns Root hash.
         */
        get: function () {
            return this._merkleTree.root;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Group.prototype, "depth", {
        /**
         * Returns the depth of the tree.
         * @returns Tree depth.
         */
        get: function () {
            return this._merkleTree.depth;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Group.prototype, "zeroValue", {
        /**
         * Returns the zero value of the tree.
         * @returns Tree zero value.
         */
        get: function () {
            return this._merkleTree.zeroes[0];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Group.prototype, "members", {
        /**
         * Returns the members (i.e. identity commitments) of the group.
         * @returns List of members.
         */
        get: function () {
            return this._merkleTree.leaves;
        },
        enumerable: false,
        configurable: true
    });
    /**
     * Returns the index of a member. If the member does not exist it returns -1.
     * @param member Group member.
     * @returns Index of the member.
     */
    Group.prototype.indexOf = function (member) {
        return this._merkleTree.indexOf(member);
    };
    /**
     * Adds a new member to the group.
     * @param identityCommitment New member.
     */
    Group.prototype.addMember = function (identityCommitment) {
        this._merkleTree.insert(BigInt(identityCommitment));
    };
    /**
     * Adds new members to the group.
     * @param identityCommitments New members.
     */
    Group.prototype.addMembers = function (identityCommitments) {
        for (var _i = 0, identityCommitments_1 = identityCommitments; _i < identityCommitments_1.length; _i++) {
            var identityCommitment = identityCommitments_1[_i];
            this.addMember(identityCommitment);
        }
    };
    /**
     * Updates a member in the group.
     * @param index Index of the member to be updated.
     * @param identityCommitment New member value.
     */
    Group.prototype.updateMember = function (index, identityCommitment) {
        this._merkleTree.update(index, identityCommitment);
    };
    /**
     * Removes a member from the group.
     * @param index Index of the member to be removed.
     */
    Group.prototype.removeMember = function (index) {
        this._merkleTree.delete(index);
    };
    /**
     * Creates a proof of membership.
     * @param index Index of the proof's member.
     * @returns Proof object.
     */
    Group.prototype.generateProofOfMembership = function (index) {
        var merkleProof = this._merkleTree.createProof(index);
        merkleProof.siblings = merkleProof.siblings.map(function (s) { return s[0]; });
        return merkleProof;
    };
    return Group;
}());

exports.Group = Group;
