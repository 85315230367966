import React from 'react';
import { Container } from '@material-ui/core';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Home from './Home/Home';

const App = () => {

    return (
        <Container maxWidth="lg">
            <Home />
        </Container>
    )
}


export default App;